<template>
    <div>
        <v-layout row wrap align-content-space-around>
            <v-flex xs12 sm6 md3>
                <v-card class="elevation-1">
                    <v-card-title>
                        <v-flex xs6 v-if="!loading">
                            <v-icon color="secondary" size="40">mdi-numeric</v-icon>
                        </v-flex>
                    <v-flex xs6 class="text-right" v-if="!loading">
                        <h3 class=" headline">{{ transactions }}</h3>
                    </v-flex>
                    <v-flex xs12 v-if="loading">
                        <div class="text-center">
                            <v-progress-circular
                            indeterminate
                            color="secondary"
                            ></v-progress-circular>
                        </div>
                    </v-flex>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <div class="body-2">Transactions&nbsp; {{ "(" + start_date + " - " + end_date + ")"  }}</div>
                    </v-card-actions>
                </v-card>
            </v-flex>
            <v-flex xs12 sm6 md3>
                <v-card class="elevation-1">
                    <v-card-title>
                        <v-flex xs6 v-if="!loading">
                            <v-icon color="secondary" size="40">mdi-cash-multiple</v-icon>
                        </v-flex>
                    <v-flex xs6 class="text-right" v-if="!loading">
                        <h3 class=" headline">{{ total_amount + " " + currency }}</h3>
                    </v-flex>
                    <v-flex xs12 v-if="loading">
                        <div class="text-center">
                            <v-progress-circular
                            indeterminate
                            color="secondary"
                            ></v-progress-circular>
                        </div>
                    </v-flex>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <div class="body-2">Total amount&nbsp; {{ "(" + start_date + " - " + end_date + ")"}}</div>
                    </v-card-actions>
                </v-card>
            </v-flex>

            <v-flex xs12 sm6 md3>
                <v-card class="elevation-1">
                    <v-card-title>
                        <v-flex xs6 v-if="!loading">
                            <v-icon color="secondary" size="40">mdi-cash</v-icon>
                        </v-flex>
                    <v-flex xs6 class="text-right" v-if="!loading">
                        <h3 class=" headline">{{ cash_payments_amount  + " " + currency }}</h3>
                    </v-flex>
                    <v-flex xs12 v-if="loading">
                        <div class="text-center">
                            <v-progress-circular
                            indeterminate
                            color="secondary"
                            ></v-progress-circular>
                        </div>
                    </v-flex>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <div class="body-2">Cash Payments &nbsp; {{ "(" + start_date + " - " + end_date + ")"}}</div>
                    </v-card-actions>
                </v-card>
            </v-flex>

            <v-flex xs12 sm6 md3>
                <v-card class="elevation-1">
                    <v-card-title>
                        <v-flex xs6 v-if="!loading">
                            <v-icon color="secondary" size="40">mdi-credit-card</v-icon>
                        </v-flex>
                    <v-flex xs6 class="text-right" v-if="!loading">
                        <h3 class=" headline">{{ credit_card_payments_amount  + " " + currency }}</h3>
                    </v-flex>
                    <v-flex xs12 v-if="loading">
                        <div class="text-center">
                            <v-progress-circular
                            indeterminate
                            color="secondary"
                            ></v-progress-circular>
                        </div>
                    </v-flex>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <div class="body-2">Card Payments &nbsp; {{ "(" + start_date + " - " + end_date + ")"}}</div>
                    </v-card-actions>
                </v-card>
            </v-flex>
        </v-layout>
    </div>
</template>

<script>

import { EventBus } from '@/main.js'

export default {
    name: "widgets",
    data: () =>({
        start_date: null,
        end_date: null,
        transactions: null,
        total_amount: null,
        credit_card_payments: null,
        credit_card_payments_amount: null,
        cash_payments: null,
        cash_payments_amount: null,
        free_vends: null,
        timeout1: null,
        interval1: null,
        currency: "",
        parameters: null,
        loading: true
        
    }),

    computed: {

    },

    methods: {
        demoData() {
            let vm = this
            clearTimeout(vm.timeout1)
            vm.loading = true
            vm.timeout1 = setTimeout(() => {
                vm.start_date = "2022-01-01";
                vm.end_date = "2023-01-16";
                vm.transactions = 10000;
                vm.total_amount = 5000;
                vm.credit_card_payments = 3000;
                vm.credit_card_payments_amount = 1500;
                vm.cash_payments = 4000;
                vm.cash_payments_amount = 2000;
                vm.free_vends = 3000;
                vm.currency = "EUR";
                vm.loading = false
            }, 1500)
        },

        fetchData() {
            let vm = this
            vm.loading = true;
            this.$store.dispatch('WIDGETS_DATA', this.parameters)
            .then(response => {
                window.console.log("fetchData")
                window.console.log(response.data)
                vm.currency = "EUR";
                vm.transactions = response.data.transactions
                vm.total_amount = response.data.total_amount
                vm.credit_card_payments = response.data.credit_card_payments
                vm.credit_card_payments_amount = response.data.credit_card_payments_amount
                vm.cash_payments = response.data.cash_payments
                vm.cash_payments_amount = response.data.cash_payments_amount
                vm.free_vends = response.data.free_vends
            })
            .catch(err => {
                window.console.error(err)
            })
            .finally(() => {
                vm.loading = false
            })
        }
    },

    created() {
        let vm = this
        /*this.demoData();  
        this.interval1 = setInterval(() => {
            vm.demoData()
        }, 60000)
        */
       //this.fetchData()
        this.interval1 = setInterval(() => {
            vm.fetchData()
        }, process.env.VUE_APP_DATA_TABLES_REFRESH_INTERVAL || 60000)
    },

    mounted() {
        let vm = this
        EventBus.$on('widgets', (data) => {
            window.console.log("### Components\Widgets@mounted ###")
            window.console.log(data)
            vm.parameters = data
            vm.start_date = data.startDate
            vm.end_date = data.endDate
            //vm.demoData()
            vm.fetchData()
        })
    },

    beforeDestroy() {
        clearTimeout(this.timeout1)
        EventBus.$off('widgets');
    },

    destroyed() {

    }
}

</script>

<style scoped>

</style>